import passwordValidator from "password-validator"

// Create a schema
const schema = new passwordValidator();

// Add properties to it
schema
    .is().min(8, "Password must be at least 8 characters long")
    .is().max(32, "32 characters is enough for a password")
    .has().uppercase(1, "Password must contain at least one uppercase symbol")
    .has().lowercase(1, "Password must contain at least one lowercase symbol")
    .has().digits(1, "Password must container at least one digit")
    .has().symbols(1, "Password must container at least one symbol")
    .has().not().spaces(1, "Password must not contain spaces");

export default schema