
function className(classes) {
    if (!classes) {
        return "";
    }
    if (typeof classes === "string") {
        return classes;
    }
    if (Array.isArray(classes)) {
        return classes.filter(c => !!c).join(" ");
    }
    if (typeof classes === "object") {
        Object.keys(classes).filter(k => !!classes[k]).join(" ");
    }
    return "";
}

export default className;