//import moment from "moment"
import { Link } from "react-router-dom"
import supabase from "lib/supabase/main"
//import renderGMT from "lib/renderGMT"
import { data2csv } from "lib/csv"
import formatNumber from "lib/format/number";
import renderGMT from "lib/renderGMT";
import ThreatName from "components/ThreatName";

export const columns = [
    {
        dataIndex: "domain",
        key: "domain",
        title: "Domain",
        render: domain => <Link to={`/reports/domain/${domain}`}>{domain}</Link>
    },
    /*{
        dataIndex: "domain_type",
        key: "domain_type",
        title: "Domain type"
    },
    {
        dataIndex: "status",
        key: "status",
        title: "Status"
    },*/
    {
        dataIndex: "threat",
        key: "threat",
        title: "Threat",
        render: (threat, row) => {
            return (
                <ThreatName id={row.threat_id} name={threat} />
            )
        }
    },
    {
        dataIndex: "threat_type",
        key: "threat_type",
        title: "Threat type"
    },
    {
        dataIndex: "expiration_date",
        key: "expiration_date",
        title: "Expires at",
        render: (d, row) => {
            return renderGMT(row.domain_expiration_date || row.expiration_date);
        }
    },
    /*{
        dataIndex: "threat_actor",
        key: "threat_actor",
        title: "Threat actor"
    },
    {
        dataIndex: "registrar",
        key: "registrar",
        title: "Registrar"
    },
    {
        dataIndex: "registrant_name",
        key: "registrant_name",
        title: "Registrant name"
    },
    {
        dataIndex: "creation_date",
        key: "creation_date",
        title: "Creation date (GMT)",
        render: renderGMT
    },
    {
        dataIndex: "expiration_date",
        key: "expiration_date",
        title: "Expiration date (GMT)",
        render: renderGMT
    },
    {
        dataIndex: "registrant",
        key: "registrant",
        title: "Registrant"
    },*/
    {
        dataIndex: "dns_log_count",
        key: "dns_log_count",
        title: "Dns log entries",
        render: formatNumber
    },
    {
        dataIndex: "tarpit_log_count",
        key: "tarpit_log_count",
        title: "Tarpit log entries",
        render: formatNumber
    }
];


export async function loadHuntReportLog(options = {}) {

    const {
        queryBy = null,
        query,
        dateRange,
        page = 0,
        domain,
        pageSize = 20,
        ip = null,
        entityId = null,
        country = null,
        csv = false,
        distinct = false,
        single = false,
        withCounter = false,
        withCount = null,
        status = null
    } = options;

    const params = {
        offset: csv ? 0 : page * pageSize,
        limit: csv ? 1000 : pageSize,
        queryBy,
        query,
        orderBy: withCounter ? "td.counter" : null,
        orderDir: withCounter ? "desc nulls last" : null,
        domain,
        ip,
        entityId,
        country,
        distinct,
        withCounter,
        expiring: status === "expiring" ? true : null,
        expired: status === "expired" ? true : null,
        withCount: withCount === null ? page === 0 : withCount,
        dateRange: dateRange ? dateRange.map(d => typeof d === "string" ? d : d.toISOString()) : null
    };

    const { data: { rows, columns, count }, error } = await supabase.functions.invoke("query", {
        body: {
            table: "hunt_report_log",
            options: params
        },
        method: "POST"
    });

    rows.forEach(row => {
        row.threat = row.t_threat;
        row.threat_type = row.t_threat_type;
        row.threat_description = row.t_threat_description;
        delete row.t_threat;
        delete row.t_threat_type;
        delete row.t_threat_description;
    })

    if (csv) {
        const text = data2csv(columns.filter(c => c !== "id" && c !== "raw"), rows);
        return text;
    }

    if (single) {
        return rows[0];
    }
    return { data: rows, columns, count, error };
}