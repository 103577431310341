

import { useCallback, useState } from "react"
import { Typography, Spin, Table, Button, Flex } from "antd"

import CountryName from "components/CountryName"
import IpLink from "components/link/Ip"

import useQuery from "hooks/useQuery"

import supabase from "lib/supabase/main"

//import countries from "dict/countries.json"
import formatNumber from "lib/format/number"
import renderGMT from "lib/renderGMT"

const { Title } = Typography;



const columns = [
    {
        dataIndex: "ip",
        key: "ip",
        title: "IP",
        render: (ip, row) => <IpLink ip={ip} companyId={row.company_id} />
    },
    {
        dataIndex: "first_seen_at",
        key: "first_seen_at",
        title: "First seen at",
        render: renderGMT
    },
    {
        dataIndex: "last_dns_log_match",
        key: "last_dns_log_match",
        title: "Last DNS log match",
        render: renderGMT
    },
    {
        dataIndex: "dns_log_counter",
        key: "dns_log_counter",
        title: "DNS requests",
        render: formatNumber
    },
    {
        dataIndex: "last_tarpit_log_match",
        key: "last_tarpit_log_match",
        title: "Last data log match",
        render: renderGMT
    },
    {
        dataIndex: "tarpit_log_counter",
        key: "tarpit_log_counter",
        title: "Data requests",
        render: formatNumber
    },
    {
        dataIndex: "country",
        key: "country",
        title: "Country",
        render: (country) => <CountryName country={country} />
    },

]

async function loadIps(options) {

    const { data: { rows, columns }, error } = await supabase.functions.invoke("query", {
        body: {
            report: "company_ips",
            options
        },
        method: "POST"
    });

    return { data: rows, columns, error }
}

function CompanyIps({ title = "Company IPs", companyId,
    titleLevel = 4, showToolbar = true }) {

    //const [ period, setPeriod ] = useState("");
    const [offset, setOffset] = useState(0);
    const { data, isLoading } = useQuery(
        loadIps,
        [companyId, offset],
        {
            append: offset > 0,
            params: {
                companyId,
                limit: 10,
                offset
            }
        }
    );

    const loadMore = useCallback(
        () => {
            setOffset(prev => prev + 10);
        },
        []
    );

    return (
        <div className="small-report report-top-threat-domains">
            {showToolbar && (
                <div className="toolbar">
                    {title && <Title level={titleLevel}>{title}</Title>}
                </div>)}
            <Flex vertical align="center" gap="0.5rem">
                <Spin spinning={isLoading}>
                    <Table
                        sticky
                        size="small"
                        bordered
                        loading={isLoading}
                        dataSource={data}
                        columns={columns}
                        rowKey="ip"
                        pagination={false} />
                </Spin>
                <Button
                    children="Load more"
                    loading={isLoading}
                    disabled={isLoading}
                    onClick={loadMore} />
            </Flex>
        </div>
    )

}

export default CompanyIps