import { Table, Typography, Flex, Button, Tag } from 'antd';
import Page from 'components/Page';
import { Portal } from 'components/Portal';
import { SyncOutlined, CheckCircleFilled, ExclamationCircleFilled } from '@ant-design/icons';

import supabase from 'lib/supabase/main';
import useQuery from 'hooks/useQuery';
import renderGMT from 'lib/renderGMT';
import aws from 'lib/aws';
import { useCallback } from 'react';

const columns = [
    {
        key: "group_name",
        title: "Service",
        dataIndex: "group_name"
    },
    {
        key: "metric_name",
        title: "Node",
        dataIndex: "metric_name"
    },
    {
        key: "status",
        dataIndex: "status",
        title: "Status",
        render: (status) => {
            if (status === "ok") {
                return (<span className='status-ok'><CheckCircleFilled /></span>)
            }
            return (<span className='status-error'><ExclamationCircleFilled /></span>)
        }
    },
    {
        key: "created_at",
        dataIndex: "created_at",
        title: "Last Updated",
        render: renderGMT
    }
];

const lambdaColumns = [
    {
        key: "function",
        title: "Function",
        dataIndex: "function"
    },
    {
        key: "errors",
        title: "Errors",
        dataIndex: "errors",
        render: (cnt, row) => {
            if (cnt === 0) {
                return (<Tag color="green">{cnt}</Tag>)
            }
            if (cnt / row.invocations < 0.05) {
                return (<Tag color="orange">{cnt}</Tag>)
            }
            else {
                return (<Tag color="red">{cnt}</Tag>)
            }
        }
    },
    {
        key: "invocations",
        title: "Invocations",
        dataIndex: "invocations"
    }
];

const fargateColumns = [
    {
        key: "name",
        title: "Service",
        dataIndex: "name"
    },
    {
        key: "errors",
        title: "Errors",
        dataIndex: "errors",
        render: (cnt, row) => {
            if (!cnt || cnt === 0) {
                return (<Tag color="green">0</Tag>)
            }
            if (row.invocations && row.invocations > 0 && cnt / row.invocations < 0.05) {
                return (<Tag color="orange">{cnt}</Tag>)
            }
            else {
                return (<Tag color="red">{cnt}</Tag>)
            }
        }
    },
    {
        key: "invocations",
        title: "Invocations",
        dataIndex: "invocations",
        render: (cnt) => {
            return cnt || '0'
        }
    }
]

async function loadDatadogLambdas() {
    const { data } = await aws.backend.get('/datadog/lambdas');
    return { data, error: null };
}

async function loadDatadogFargate() {
    const { data } = await aws.backend.get('/datadog/fargate');
    return { data, error: null };
}

async function loadSystemHealth() {
    const { data, error } = await supabase.from('system_health').select('*');
    if (error) {
        console.error('Error fetching system health:', error.message);
        return null;
    }
    return { data, error };
}

function SystemHealth() {

    const { data: systemHealth,
        isLoading,
        refetch: refetchHealth } = useQuery(
            loadSystemHealth,
            []
        );

    const { data: lambdas,
        refetch: refetchLambdas,
        isLoading: isLambdasLoading } = useQuery(
            loadDatadogLambdas,
            []
        );

    const { data: fargate,
        refetch: refetchFargate,
        isLoading: isFargateLoading } = useQuery(
            loadDatadogFargate,
            []
        );

    const onRefreshClick = useCallback(
        () => {
            refetchHealth();
            refetchLambdas();
            refetchFargate();
        },
        [refetchHealth, refetchLambdas, refetchFargate]
    );

    return (
        <Page className="page-system-health">
            <Portal host="header">
                <Flex justify="space-between" align='center'>
                    <Typography.Title level={1}>System Health</Typography.Title>
                    <Button
                        size="large"
                        type="text"
                        icon={<SyncOutlined spin={isLoading} />}
                        onClick={onRefreshClick} />
                </Flex>
            </Portal>
            <div className='page'>
                <Table
                    loading={isLoading}
                    dataSource={systemHealth}
                    columns={columns}
                    pagination={false} />
                <br />
                <Typography.Title level={3}>Fargate (last 24 hours)</Typography.Title>
                <Table
                    loading={isFargateLoading}
                    dataSource={fargate}
                    rowKey={"name"}
                    columns={fargateColumns}
                    pagination={false} />
                <br />
                <Typography.Title level={3}>Lambdas (last hour)</Typography.Title>
                <Table
                    loading={isLambdasLoading}
                    dataSource={lambdas}
                    rowKey={"function"}
                    columns={lambdaColumns}
                    pagination={false} />

            </div>
        </Page>
    );
}

export default SystemHealth;