import { useMemo, useState } from "react"

import useSwallowEventCallback from "hooks/useSwallowEventCallback"

function ColumnList({ data, columns = 5, previewSize = 20, renderItem }) {

    const [showAll, setShowAll] = useState(false);
    const preview = useMemo(() => data.slice(0, previewSize), [data, previewSize]);
    const count = data.length;
    const enableToggle = previewSize > 0 && count > previewSize;

    const outputData = useMemo(
        () => !enableToggle || showAll || count <= previewSize ? data : preview,
        [showAll, data, preview, previewSize, count, enableToggle]
    );

    const toggleMore = useSwallowEventCallback(
        () => setShowAll(prev => !prev),
        []
    );

    return (
        <div className="column-list" style={{ columnCount: columns }}>
            {outputData.map(renderItem)}
            {enableToggle &&
                <a href="/#" onClick={toggleMore}>{showAll ? "less..." : "more..."}</a>}
        </div>
    )

}

export default ColumnList