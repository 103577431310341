import { WindowsOutlined, AppleOutlined, LinuxOutlined, AndroidOutlined } from "@ant-design/icons"
import { useMemo } from "react";
import MalwareLink from "./link/Malware";
// import { ReactComponent as JsIcon } from "svg/js.svg"
// import { ReactComponent as PhpIcon } from "svg/php.svg"

const osIcons = {
    "win": <WindowsOutlined />,
    "osx": <AppleOutlined />,
    "elf": <LinuxOutlined />,
    "apk": <AndroidOutlined />,
    // "js": <JsIcon />,
    // "php": <PhpIcon />,
};

function ThreatName({ id, name, withLink = true }) {

    const icon = useMemo(
        () => {
            if (!id) {
                return null;
            }
            const type = id.split(".")[0];
            return osIcons[type] || null;
        },
        [id]
    );

    if (!withLink) {
        return (
            <>
                {icon}
                {icon ? " " : null}
                {name}
            </>
        )
    }

    return (
        <MalwareLink malware={{ id, name }}>
            {icon}
            {icon ? " " : null}
            {name}
        </MalwareLink>
    )
}

export default ThreatName