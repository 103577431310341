import { Typography, Button, Table, Spin } from "antd"
import { useCallback, useEffect, useMemo, useState } from "react"
import Chart from "react-apexcharts"
import moment from "moment"

import Page from "components/Page"
import MalwareSelect from "components/selector/MalwareSelect"

import supabase from "lib/supabase/main"
import formatNumber from "lib/format/number"
import { getDefaultOptions, getDefaultSeries, DEFAULT_HEIGHT } from "hooks/useApexChart"
import { Portal } from "components/Portal"
import ThreatName from "components/ThreatName"

async function loadTopThreats(options = {}) {

    const { data: { rows, columns }, error } = await supabase.functions.invoke("query", {
        body: {
            report: "top_threats",
            options,
            replica: true
        },
        method: "POST"
    });

    const threatIds = rows.map(row => row.threat_id);
    const { data: threats } = await supabase.from("threat").select().in("id", threatIds);

    rows.forEach(row => {
        const threat = threats.find(t => t.id === row.threat_id);
        row.name = threat?.name;
    });

    return { data: rows, columns, error }
}


async function loadThreatsByDate(options = {}) {

    const after = moment().subtract(31, "day").format("YYYY-MM-DD");
    const { id } = options;
    const { data, error } = await supabase.from("threat_date")
        .select()
        .in("threat_id", id)
        .gt("date", after);

    const threatIds = data.map(row => row.threat_id);
    const { data: threats } = await supabase.from("threat").select().in("id", threatIds);

    data.forEach(row => {
        const threat = threats.find(t => t.id === row.threat_id);
        row.name = threat?.name;
    });

    return { data, error };
}


const generalColumns = [
    {
        key: "threat_id",
        dataIndex: "threat_id",
        title: "Threat",
        render: (id, row) => {
            return (
                <ThreatName id={id} name={row.name} />
            )
        }
    },
    {
        key: "dns_log_counter",
        dataIndex: "dns_log_counter",
        title: "Dns requests",
        render: formatNumber
    },
    {
        key: "tarpit_log_counter",
        dataIndex: "tarpit_log_counter",
        title: "Tarpit requests",
        render: formatNumber
    }
];


const chartConfigs = [
    {
        field: "dns_log_counter",
        title: "Dns requests"
    },
    {
        field: "tarpit_log_counter",
        title: "Tarpit requests"
    }
]

function MalwaresReport() {

    const [threats, setThreats] = useState([]);
    const [stats, setStats] = useState([]);
    const [statsByDate, setStatsByDate] = useState([]);
    const [statsLoading, setStatsLoading] = useState(true);
    const [statsByDateLoading, setStatsByDateLoading] = useState(false);

    const loading = useMemo(
        () => statsByDateLoading || statsLoading,
        [statsByDateLoading, statsLoading]
    );

    const loadGeneralStats = useCallback(
        async (options) => {
            setStatsLoading(true);
            const { data } = await loadTopThreats(options);
            setStats(data);
            setStatsLoading(false);
        },
        []
    );

    const loadStatsByDate = useCallback(
        async (options) => {
            setStatsByDateLoading(true);
            const { data } = await loadThreatsByDate(options);
            setStatsByDate(data);
            setStatsByDateLoading(false);
        },
        []
    );


    const dates = useMemo(
        () => {
            return statsByDate.map(row => row.date)
                .filter((d, inx, self) => self.indexOf(d) === inx)
                .sort()
        },
        [statsByDate]
    );

    const charts = useMemo(
        () => {
            const threats = statsByDate.map(row => row.threat_id)
                .filter((c, inx, self) => self.indexOf(c) === inx);

            return chartConfigs.map(cc => {

                const series = threats.map(tid => {
                    const stats = statsByDate.filter(row => row.threat_id === tid);

                    return getDefaultSeries(
                        dates.map(date => {
                            return stats.find(row => row.date === date) || {
                                threat: tid,
                                dns_log_counter: 0,
                                tarpit_log_counter: 0
                            }
                        }),
                        {
                            y: {
                                field: cc.field,
                                // name: countriesDict.find(ct => ct.code === c).name
                                name: stats[0].name
                            }
                        }
                    )
                });

                const options = getDefaultOptions(
                    dates.map(date => ({ date: (new Date(date)).getTime() }))
                );

                const chart = {
                    title: cc.title,
                    config: {
                        options,
                        series,
                        height: DEFAULT_HEIGHT,
                        type: "area"
                    }
                };
                return chart;
            })
        },
        [statsByDate, dates]
    );


    const onViewClick = useCallback(
        async () => {
            const ids = [];
            threats.forEach(t => ids.push(typeof t === "string" ? t : t.value));
            loadStatsByDate({ id: ids });
            loadGeneralStats({ threatId: ids, limit: false });
        },
        [threats, loadStatsByDate, loadGeneralStats]
    );

    useEffect(
        () => {
            setStatsLoading(true);
            loadTopThreats({ limit: 5 }).then(({ data }) => {
                const threats = data.map(row => row.threat_id);
                const options = data.map(row => ({ value: row.threat_id, label: row.name }));
                setThreats(options);
                setStats(data);
                setStatsLoading(false);
                loadStatsByDate({ id: threats });
            })
        },
        // eslint-disable-next-line
        []
    );


    return (
        <Page className="page-reports-countries">
            <Portal host="header">
                <Typography.Title level={1}>Threats</Typography.Title>
                <div className="toolbar">
                    <MalwareSelect
                        className="page-reports-countries-selector"
                        size="large"
                        placeholder="Select threats"
                        mode="multiple"
                        value={threats}
                        onChange={setThreats}
                        disabled={loading}
                        allowClear />
                    <Button
                        onClick={onViewClick}
                        loading={loading}
                        disabled={loading}
                        size="large"
                        children="View report" />
                </div>
            </Portal>
            <section className="gradient-section">
                <Typography.Title level={2}>General stats</Typography.Title>
                <Table
                    sticky
                    size="small"
                    loading={loading}
                    dataSource={stats}
                    columns={generalColumns}
                    rowKey="threat_id"
                    pagination={false} />
            </section>
            {statsByDate.length > 0 &&
                charts.map((chart, inx) => (
                    <section key={inx}>
                        <Spin spinning={loading}>
                            <Typography.Title level={2}>{chart.title}</Typography.Title>
                            <div className="chart-wrapper">
                                <Chart {...chart.config} />
                            </div>
                        </Spin>
                    </section>
                ))}
        </Page>
    );
}

export default MalwaresReport