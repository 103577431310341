
let fn = null,
    prefix = null;


export default function dom_removeListener(el, event, func) {

    if (fn === null) {
        if (el.removeEventListener) {
            fn = "removeEventListener";
            prefix = "";
        }
        else {
            fn = "detachEvent";
            prefix = "on";
        }
    }

    el[fn](prefix + event, func);
}
