import { useCallback, useState } from "react"


function useArrayToggle(defaultState = []) {

    const [state, setState] = useState(defaultState);
    const toggle = useCallback(
        (key, state) => {
            setState(prev => {
                const inx = prev.indexOf(key);
                const next = [...prev];
                if (state === undefined) {
                    state = !(inx === -1);
                }
                if (state && inx === -1) {
                    next.push(key);
                }
                else if (!state && inx !== -1) {
                    next.splice(inx, 1);
                }
                return next;
            })
        },
        []
    );

    const toggleOn = useCallback(
        key => toggle(key, true),
        [toggle]
    );

    const toggleOff = useCallback(
        key => toggle(key, false),
        [toggle]
    );

    return [state, toggle, toggleOn, toggleOff, setState];
}

export default useArrayToggle