/**
 * Execute <code>fn</code> asynchronously
 * @function async
 * @param {Function} fn Function to execute
 * @param {number} timeout Execute after timeout (number of ms)
 * @param {Object} context Function's context (this)
 * @param {[]} args Arguments to pass to fn
 */
module.exports = function async(fn, timeout, context, args) {
    return new Promise((resolve, reject) => {
        return setTimeout(() => resolve(fn.apply(context, args || [])), timeout || 0);
    })
};