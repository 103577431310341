
async function validate(name, value, validator, values) {

    let error = "";
    let res;

    if (validator) {
        for (let i = 0, l = validator.length; i < l; i++) {
            res = validator[i][0](value, name, values);
            if (res instanceof Promise) {
                res = await res;
            }
            if (res === false) {
                error = validator[i][1];
                break;
            }
            if (typeof res === "string") {
                error = res;
                break;
            }
        }
    }

    return error || null;
}

export default validate