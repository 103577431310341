import { useMemo } from "react";
import { Link } from "react-router-dom";

function MalwareLink({ malware, children }) {
    const id = useMemo(
        () => {
            if (typeof malware === "string") {
                return malware;
            }
            if (malware) {
                return malware.id;
            }
            return null;
        },
        [malware]
    );

    const name = useMemo(
        () => {
            if (typeof malware === "string") {
                return malware;
            }
            if (malware) {
                return malware.name;
            }
            return null;
        },
        [malware]
    );

    return (
        <Link to={`/reports/malware/${id}`} children={children || name} />
    )
}

export default MalwareLink;