

let fn = null,
    prefix = null;

export default function dom_addListener(el, event, func, opt) {

    if (fn === null) {
        if (el.addEventListener) {
            fn = "addEventListener";
            prefix = "";
        }
        else {
            fn = "attachEvent";
            prefix = "on";
        }
    }

    opt = opt || {};
    opt.capture = opt.capture || false;

    el[fn](prefix + event, func, opt);

    return func;
}
