import { useState, useCallback } from "react"
import { Typography, Select, Table, Input, Button, Flex } from "antd"
import Page from "components/Page"
import { Portal } from "components/Portal"
import { SearchOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom"

import useQuery from "hooks/useQuery";
import renderGMT from "lib/renderGMT"
import { loadDnsIntelLog, DEFAULT_PAGE_SIZE } from "api/dnsIntel"

const { Title } = Typography;

// const params = [
//     {
//         name: "query",
//         default: null
//     },
// ];

const queryOptions = [
    { value: "domain", label: "Domain" },
    { value: "answer", label: "Answer" },
    { value: "asn_organization", label: "Organization" },
];

const columns = [
    {
        title: "Domain",
        dataIndex: "domain",
        key: "domain",
        render: domain => <Link to={`/reports/domain/${domain}`}>{domain}</Link>
    },
    {
        title: "Record type",
        dataIndex: "record_type",
        key: "record_type",
    },
    {
        title: "Answer",
        dataIndex: "answer",
        key: "answer",
    },
    {
        title: "First seen",
        dataIndex: "first_seen",
        key: "first_seen",
        render: renderGMT
    },
    {
        title: "Last seen",
        dataIndex: "last_seen",
        key: "last_seen",
        render: renderGMT
    },
    {
        title: "Answer organization",
        dataIndex: "asn_organization",
        key: "asn_organization",
    },
    {
        title: "Geo",
        dataIndex: "geolocation_country",
        key: "geolocation_country",
        render: (v, r) => {
            const geo = [r.geolocation_country, r.geolocation_city].filter(Boolean).join(", ");
            return geo
        }
    }
]




function DnsIntelLog() {

    const [page, setPage] = useState(0);
    const [query, setQuery] = useState("");
    const [queryBy, setQueryBy] = useState("domain");
    const [appliedQuery, setAppliedQuery] = useState("");

    const { data, isLoading, extraData: { hasMore } } = useQuery(
        loadDnsIntelLog,
        [page, appliedQuery],
        {
            append: page > 0,
            params: {
                page,
                pageSize: DEFAULT_PAGE_SIZE,
                query: appliedQuery,
                queryBy
            }
        },
        [appliedQuery]
    );

    const onSearchClick = useCallback(
        () => {
            setPage(0);
            setAppliedQuery(query);
        },
        [query]
    );

    const onKeyDown = useCallback(
        (e) => {
            if (e.key === "Enter") {
                onSearchClick();
            }
        },
        [onSearchClick]
    );

    const onQueryChange = useCallback(
        (e) => {
            setQuery(e.target.value);
        },
        [setQuery]
    );

    const loadMore = useCallback(
        () => {
            setPage(prev => prev + 1);
        },
        []
    );


    return (
        <Page className="page-registrar-log" htmlClassName="html-page-registrar-log">
            <Portal host="header">
                <Title level={1}>DNS intel</Title>
                <div className="toolbar">

                    <Input
                        addonBefore={
                            <Select
                                size="large"
                                allowClear
                                placeholder="Query by"
                                value={queryBy}
                                onChange={setQueryBy}
                                options={queryOptions} />
                        }
                        allowClear
                        size="large"
                        onKeyDown={onKeyDown}
                        prefix={<SearchOutlined />}
                        onChange={onQueryChange}
                        value={query} />

                    <Button
                        size="large"
                        loading={isLoading}
                        disabled={isLoading}
                        onClick={onSearchClick}
                        children="Search" />
                </div>
            </Portal>
            <Table
                sticky
                size="small"
                bordered
                loading={isLoading}
                dataSource={data}
                columns={columns}
                rowKey="id"
                pagination={false}
                expandable={false} />
            {hasMore && (
                <Flex style={{ marginTop: "1rem" }} justify="center">
                    <Button
                        onClick={loadMore}
                        loading={isLoading}
                        disabled={isLoading}>Load more</Button>
                </Flex>
            )}
        </Page>
    )
}

export default DnsIntelLog