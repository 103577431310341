
export default function is(node, selector) {

    if (typeof selector === "string") {
        if (selector[0] === '#' && node.id === selector.substring(1)) {
            return true;
        }
        if (selector[0] === '.' && node.classList && node.classList.contains(selector.substring(1))) {
            return true;
        }
        if (selector.toLowerCase() === node.nodeName.toLowerCase()) {
            return true;
        }
    }
    else {
        return node === selector;
    }
    return false;
}