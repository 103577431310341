import { useMemo, useState } from "react"
import { Segmented, Spin, Typography } from "antd"
import moment from "moment"
import Chart from "react-apexcharts"

import DnsOrTarpitSelector from "components/selector/DnsOrTarpitSelect"

import useQuery from "hooks/useQuery"
import fillDates from "lib/fillDates"
import periodOptions from "lib/periodOptions"
import supabase from "lib/supabase/main"
import useApexChart from "hooks/useApexChart"

const { Title } = Typography;


async function loadCountryByDate(options = {}) {

    const { data: { rows, columns }, error } = await supabase.functions.invoke("query", {
        body: {
            report: "country_by_date",
            options
        },
        method: "POST"
    });

    return { data: rows, columns, error }
}


function CountryByDate({
    title = "Requests by date",
    params = {},
    showToolbar = true,
    chartType = "area" }) {

    const [basedOn, setBasedOn] = useState(null);
    const [period, setPeriod] = useState("last30");

    const { data, isLoading } = useQuery(
        loadCountryByDate,
        [period],
        {
            prepare: data => {
                return fillDates(period, data, "date", (d) => d.format("YYYY-MM-DD"), { cnt: 0 });
            },
            params: { ...params, basedOn, period }
        }
    );

    const chartData = useMemo(
        () => {
            return data.map(row => {
                let cnt = 0;
                if (basedOn === "dns") {
                    cnt = parseInt(row.dns_log_count);
                }
                else if (basedOn === "tarpit") {
                    cnt = parseInt(row.tarpit_log_count);
                }
                else {
                    cnt = parseInt(row.dns_log_count) + parseInt(row.tarpit_log_count);
                }
                return {
                    date: moment(row.date).utc().toDate().getTime(),
                    cnt
                }
            })
        },
        [data, basedOn]
    );

    const chart = useApexChart({
        data: chartData,
        type: chartType,
        axes: {
            y: {
                name: "Requests"
            }
        }
    });

    return (
        <div>
            {showToolbar &&
                <div className="toolbar">
                    {title && <Title level={4}>{title}</Title>}
                    <Segmented className="toolbar-right"
                        value={period} onChange={setPeriod} options={periodOptions} />
                    <DnsOrTarpitSelector
                        size="small"
                        value={basedOn}
                        onChange={setBasedOn} />
                </div>}
            <Spin spinning={isLoading}>
                <div className="chart-wrapper">
                    {data.length > 0 &&
                        <Chart {...chart} />}
                </div>
            </Spin>
        </div>
    )

}

export default CountryByDate