import { Descriptions, Spin, Tag, Typography, Table } from "antd";
import { useLocation } from "react-router-dom";
import { useMemo } from "react";

import Page from "components/Page";
import { Portal } from "components/Portal";
import ThreatByDate from "components/report/TheatByDate";
import DnsLog from "components/table/DnsLog";
import TarpitLog from "components/table/TarpitLog";
import TopCountries from "components/report/TopCountries";
import TopVictims from "components/report/TopVictims";
import LayoutWithMenu from "components/LayoutWithMenu";

import supabase from "lib/supabase/main";
import useQuery from "hooks/useQuery";
import ThreatName from "components/ThreatName";


async function loadMalwareInfo({ malwareId }) {
    return await supabase.from("threat")
        .select("*")
        .eq("id", malwareId);
}


const menu = [
    {
        key: "info",
        label: "Info"
    },
    {
        key: "top-countries",
        label: "Top countries"
    },
    {
        key: "top-victims",
        label: "Top victims"
    },
    {
        key: "dns-log",
        label: "DNS log"
    },
    {
        key: "tarpit-log",
        label: "Tarpit log"
    }
];

const pages = {
    "info": {
        load: async ({ malware, setData, toggleLoading }) => {
            toggleLoading("info", true);
            const tags = [];

            if (malware) {
                if (malware.type) {
                    tags.push({
                        key: "type",
                        label: "Type",
                        children: [
                            <Tag key="type">{malware.type}</Tag>
                        ]
                    })
                }

                if (malware.malpedia_data?.alt_names &&
                    malware.malpedia_data.alt_names.length > 0) {
                    tags.push({
                        key: "alt_names",
                        label: "Alt Names",
                        children: malware.malpedia_data.alt_names.map(name => (
                            <Tag key={name}>{name}</Tag>
                        ))
                    });
                }

                if (malware.malpedia_data?.attribution &&
                    malware.malpedia_data.attribution.length > 0) {
                    tags.push({
                        key: "attribution",
                        label: "Attribution",
                        children: malware.malpedia_data.attribution.map(attribution => (
                            <Tag key={attribution}>{attribution}</Tag>
                        ))
                    });
                }

                if (malware.malpedia_data?.urls &&
                    malware.malpedia_data.urls.length > 0) {
                    const rows = malware.malpedia_data.urls.map(url => ({ url }));
                    const columns = [
                        {
                            title: "URL",
                            dataIndex: "url",
                            key: "url",
                            render: (url) => (
                                <a key={url} href={url} target="_blank" rel="noreferrer">{url}</a>
                            )
                        }
                    ]
                    tags.push({
                        key: "urls",
                        label: "URLs",
                        children: (
                            <Table
                                style={{ width: "100%" }}
                                bordered={false}
                                size="small"
                                dataSource={rows}
                                columns={columns} />
                        )
                    });
                }

                if (malware.yara_available) {
                    const url = `https://malpedia.caad.fkie.fraunhofer.de/yara/${malware.id}`;
                    tags.push({
                        key: "yara",
                        label: "YARA rules",
                        children: [
                            <a href={url} target="_blank" rel="noreferrer">Download YARA rules</a>
                        ]
                    });
                }
            }

            console.log(tags)

            setData(prev => {
                if (tags.length === 0) {
                    return prev;
                }
                return { ...prev, "info": tags }
            });
            toggleLoading("info", false);
        },
        render: ({ data, loading }) => {
            if (loading) {
                return <Spin spinning />
            }
            return (
                <Descriptions
                    bordered
                    className="page-malware-tags"
                    column={1}
                    items={data} />
            )
        }
    },
    "top-countries": {
        render: ({ malwareId }) => {
            return (
                <TopCountries
                    title="Top countries"
                    titleLevel={2}
                    bordered
                    params={{
                        threatId: malwareId,
                        period: "last7"
                    }} />
            )
        }
    },
    "top-victims": {
        render: ({ malwareId }) => {
            return (
                <TopVictims
                    title="Top victims"
                    titleLevel={2}
                    bordered
                    params={{
                        threatId: malwareId,
                    }} />
            )
        }
    },
    "dns-log": {
        title: "DNS log",
        render: ({ malwareId }) => {
            return (
                <DnsLog
                    initialParams={{
                        threatId: malwareId,
                        // withThreat: true
                    }} />
            )
        }
    },
    "tarpit-log": {
        title: "Tarpit log",
        render: ({ malwareId }) => {
            return (
                <TarpitLog
                    initialParams={{
                        threatId: malwareId,
                        // withThreat: true
                    }} />
            )
        }
    }
};


function MalwarePage() {

    const { pathname } = useLocation();
    const malwareId = pathname.split("/").pop();

    const { data: malware, isLoading } = useQuery(
        loadMalwareInfo,
        [malwareId],
        {
            params: {
                malwareId
            },
            initialData: null,
            prepare: (data) => data[0],
        }
    );

    const threatByDateParams = useMemo(
        () => ({
            threatId: malwareId
        }),
        [malwareId]
    );

    return (
        <Page className="page-malware">
            <Portal host="header">
                <Spin spinning={isLoading}>
                    <Typography.Title level={1}>
                        <ThreatName
                            withLink={false}
                            id={malwareId}
                            name={malware?.name} />
                    </Typography.Title>
                    <Typography.Text>{malware?.description}</Typography.Text>
                    <ThreatByDate
                        params={threatByDateParams}
                        showToolbar={false}
                        chartType="bar" />
                </Spin>
            </Portal>

            <LayoutWithMenu
                menu={menu}
                pages={pages}
                defaultSelectedKeys={["info"]}
                defaultOpenKeys={["info"]}
                pageLoadOptions={{ malwareId, malware }}
                pageRenderOptions={{ malwareId, malware }} />

        </Page>
    )
}

export default MalwarePage;