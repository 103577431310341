import { Route, Routes, Navigate } from "react-router-dom"
import { Fragment } from "react"

import PageHuntQueue from "pages/hunt/Queue"
import PageProfile from "pages/user/Profile"
import PageTakedown from "pages/hunt/Takedown"
import PageExpiringDomains from "pages/hunt/Expiring"
import PageNotifications from "pages/user/Notifications"

import ScrollToTop from "components/ScrollToTop"
import Header from "components/Header"
import Footer from "components/Footer"
import useHtmlClass from "hooks/useHtmlClass"

function RegistrarView() {

    useHtmlClass("html-registrar-view");

    return (
        <Fragment>
            <ScrollToTop />
            <Header />
            <Routes>
                <Route path="/" element={<PageHuntQueue />} />
                <Route path="/takedown-requests" element={<PageTakedown />} />
                <Route path="/expiring" element={<PageExpiringDomains />} />
                <Route path="/profile" element={<PageProfile />} />
                <Route path="/notifications" element={<PageNotifications />} />
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
            <Footer />
        </Fragment>
    )
}

export default RegistrarView