import { useCallback, useContext, useMemo } from "react"
import { Typography, Spin, Button, Flex } from "antd"
import { useMatch, useNavigate } from "react-router-dom"

import Page from "components/Page"
import DnsLogTable from "components/table/DnsLog"
import HuntReportLogTable from "components/table/HuntReportLog"
import IpRegistryCompanyCidrTable from "components/table/IpRegistryCompanyCidr"
import TarpitLogTable from "components/table/TarpitLog"
import CompanyByDate from "components/report/CompanyByDate"
import IpTarpitPort from "components/table/IpTarpitPort"

import supabase from "lib/supabase/main"
import useQuery from "hooks/useQuery"
import AppContext from "context/AppContext"
import { Portal } from "components/Portal"
import LayoutWithMenu from "components/LayoutWithMenu"

const { Title } = Typography;

const menu = [
    {
        key: "hunt-report",
        label: "Threats"
    },
    {
        key: "tarpit-log",
        label: "Tarpit log"
    },
    {
        key: "dns-log",
        label: "DNS log"
    },
    {
        key: "tarpit-ips",
        label: "Tarpit IPs"
    },
    {
        key: "company-cidrs",
        label: "CIDRs"
    }
];

const pages = {
    "hunt-report": {
        title: "Threats",
        render: ({ entityId }) => {
            return (
                <HuntReportLogTable
                    initialParams={{ entityId, distinct: true }} />
            )
        }
    },
    "tarpit-log": {
        title: "Tarpit log",
        render: ({ entityId, onLoadMoreTarpit }) => {
            return (
                <TarpitLogTable
                    ipLink
                    excludeQueryOptions={["companyName", "companyDomain"]}
                    onLoadMore={onLoadMoreTarpit}
                    initialParams={{
                        entityId,
                        withPayload: true,
                        withCompany: false,
                        withCidr: false,
                        withThreat: true,
                        pageSize: 10
                    }} />
            )
        }
    },
    "dns-log": {
        title: "DNS log",
        render: ({ entityId, onLoadMoreDns }) => {
            return (
                <DnsLogTable
                    ipLink
                    excludeQueryOptions={["companyName", "companyDomain"]}
                    onLoadMore={onLoadMoreDns}
                    initialParams={{
                        entityId,
                        withCompany: false,
                        withCidr: false,
                        withThreat: true,
                        pageSize: 10
                    }} />
            )
        }
    },
    "tarpit-ips": {
        title: "Tarpit IPs",
        render: ({ entityId }) => {
            return (
                <IpTarpitPort
                    initialParams={{ entityId, withCountry: true }} />
            )
        }
    },
    "company-cidrs": {
        title: "CIDRs",
        render: ({ entityId }) => {
            return (
                <IpRegistryCompanyCidrTable
                    initialParams={{ entityId, withCount: true }} />
            )
        }
    }
};

function Company() {

    const navigate = useNavigate();
    const { params: { id } } = useMatch("/reports/entity/:id");
    const app = useContext(AppContext);

    const { data: company, isLoading: isCompanyLoading } = useQuery(
        () => supabase.from("entity").select().eq("id", id),
        [id],
        {
            initialData: {},
            prepare: data => data[0] || {}
        }
    )

    const companyByDateParams = useMemo(
        () => ({ entityId: id }),
        [id]
    );

    const onAddToWatchlist = useCallback(
        () => app.trigger("add-companies-to-watchlist", [{ id: company.id, name: company.name }]),
        [app, company]
    );

    const onLoadMoreTarpit = useCallback(
        () => {
            navigate(`/logs/tarpit?entityId=${id}`)
        },
        [id, navigate]
    );

    const onLoadMoreDns = useCallback(
        () => {
            navigate(`/logs/dns?entityId=${id}`)
        },
        [id, navigate]
    );

    return (
        <Page className="page-company" htmlClassName="html-page-company">
            <Portal host="header">
                <Spin spinning={isCompanyLoading}>
                    <Flex justify="space-between" className="header-single-row">
                        <Title level={1}>{company.name} ({company.domain}) {company.type}</Title>
                        <Button
                            size="large"
                            type="primary"
                            children="Add to watchlist"
                            onClick={onAddToWatchlist} />
                    </Flex>
                </Spin>
                <CompanyByDate
                    chartType="bar"
                    showToolbar={false}
                    title="Requests by date"
                    params={companyByDateParams} />
            </Portal>

            <LayoutWithMenu
                menu={menu}
                pages={pages}
                defaultSelectedKeys={["hunt-report"]}
                defaultOpenKeys={["hunt-report"]}
                pageRenderOptions={{ entityId: id, onLoadMoreTarpit, onLoadMoreDns }} />
        </Page>
    )
}

export default Company