
import is from "./is"

export default function findParent(parent, selector) {

    while (parent && parent !== document.body) {
        if (is(parent, selector)) {
            return parent;
        }
        parent = parent.parentNode;
    }

    return null;
}