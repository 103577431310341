import { Typography } from "antd";
import Page from "components/Page";
import { Portal } from "components/Portal";
import { NotificationsDialog } from "components/dialog/Notifications";

function NotificationsPage() {

    return (
        <Page>
            <Portal host="header">
                <Typography.Title level={1}>Notifications</Typography.Title>
            </Portal>
            <div className="page">
                <NotificationsDialog asPage />
            </div>
        </Page>
    )
}

export default NotificationsPage;