import { useState, useEffect, useCallback, Fragment } from "react";
import { Layout, Menu, Flex, Typography } from "antd";

import useArrayToggle from "hooks/useArrayToggle";
import useDictRef from "hooks/useDictRef";

const { Title } = Typography;
const { Sider, Content } = Layout;
export const DEFAULT_TITLE_LEVEL = 2;

function LayoutWithMenu({
    children, menu, pages, defaultTitleLevel = DEFAULT_TITLE_LEVEL,
    layoutClassName, contentClassName,
    pageRenderOptions, pageLoadOptions, defaultSelectedPages,
    layoutProps, siderProps, contentProps, ...props }) {

    const [selected, , , , setSelected] = useArrayToggle(props.defaultSelectedKeys || []);
    const [loading, toggleLoading] = useArrayToggle([]);
    const [data, setData] = useState({});
    const ref = useDictRef({ toggleLoading, data, loading, pageLoadOptions });

    const onMenuSelect = useCallback(
        ({ key }) => {
            setSelected([key]);
            props.onSelect && props.onSelect([key])
        },
        // eslint-disable-next-line
        [setSelected, props.onSelect]
    );

    useEffect(
        () => {
            for (const key of selected) {
                if (ref.loading.includes(key) || ref.data[key]) {
                    continue;
                }
                pages[key].load && pages[key].load({
                    ...pageLoadOptions,
                    toggleLoading: ref.toggleLoading,
                    setData
                });
            }
        },
        // eslint-disable-next-line
        [selected, pages, pageLoadOptions]
    );

    return (
        <Layout className={layoutClassName} {...layoutProps}>
            <Sider {...siderProps}>
                <Menu
                    style={{ position: "sticky", top: "1rem" }}
                    mode="inline"
                    theme="dark"
                    items={menu}
                    {...props}
                    selectedKeys={selected}
                    onSelect={onMenuSelect} />
            </Sider>
            <Content className={contentClassName} {...contentProps}>
                {children}
                {pages && (
                    <Flex vertical gap="1rem">
                        {Object.entries(pages).filter(([key, page]) => selected.includes(key)).map(([key, page]) => (
                            <Fragment key={key}>
                                {page.title && <Title level={defaultTitleLevel}>{page.title}</Title>}
                                {page.render({
                                    data: data[key],
                                    loading: loading.includes(key),
                                    ...pageRenderOptions
                                })}
                            </Fragment>
                        ))}
                    </Flex>
                )}
            </Content>
        </Layout>
    );
}

export default LayoutWithMenu;