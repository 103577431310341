import { useCallback, useContext, useMemo, useState } from "react"
import { useMatch, Link } from "react-router-dom"
import { Flex, Spin, Typography } from "antd"
import moment from "moment"
import Chart from "react-apexcharts"

import Page from "components/Page"
import DnsLogTable from "components/table/DnsLog"
import TarpitLogTable from "components/table/TarpitLog"
import TopVictims from "components/report/TopVictims"
import IpTarpitPort from "components/table/IpTarpitPort"
import { Portal } from "components/Portal"
import DnsByDate from "components/report/DnsByDate"
import TarpitByDate from "components/report/TarpitByDate"
import ByDate from "components/report/ByDate"
import CompanyThreats from "components/report/company/CompanyThreats"
import TopCountries from "components/report/TopCountries"
import IpLink from "components/link/Ip"
import Watchlists from "components/watchlist/List"
import LastActiveIpsMap from "components/report/LastActiveIpsMap"
import PotentialPhishingDomains from "components/table/PotentialPhishingDomains"
import CompanyIps from "components/report/company/CompanyIps"
import LayoutWithMenu from "components/LayoutWithMenu"

import supabase from "lib/supabase/main"
import useQuery from "hooks/useQuery"
import formatNumber from "lib/format/number"
import useHtmlClass from "hooks/useHtmlClass"
import countriesDict from "dict/countries.json"
import { getDefaultOptions, getDefaultSeries, DEFAULT_HEIGHT } from "hooks/useApexChart"

import colorBetween from "lib/colorBetween"
import AppContext from "context/AppContext"

const colorStart = "f1f000";
const colorEnd = "d70000";

const chartConfigs = [
    {
        field: "ip_count",
        title: "Ip count"
    },
    {
        field: "dns_log_count",
        title: "Dns requests"
    },
    {
        field: "tarpit_log_count",
        title: "Tarpit requests"
    }
];

const menu = [
    {
        key: "watchlists",
        label: "Watchlists"
    },
    {
        key: "potential-phishing-domains",
        label: "Potential Phishing Domains"
    },
    {
        key: "company-ips",
        label: "Company IPs"
    },
    {
        key: "top-victims",
        label: "Top victims"
    },
    {
        key: "top-countries",
        label: "Top countries"
    },

    {
        key: "ip-count-by-date",
        label: "Ip count by date"
    },
    {
        key: "dns-by-date",
        label: "DNS by date"
    },
    {
        key: "tarpit-by-date",
        label: "Tarpit by date"
    },
    {
        key: "threats",
        label: "Threats"
    },
    {
        key: "affected-ports",
        label: "Affected ports"
    },

    {
        key: "tarpit-log",
        label: "Tarpit Log"
    },
    {
        key: "dns-log",
        label: "DNS Log"
    },

];

const pageConfigs = {

    "dns-log": {
        title: "DNS Log",
        render: ({ id, ipLink }) => {
            const params = {
                customerCompanyId: id,
                withCompany: false,
                withCidr: false,
                withThreat: true
            }
            return (
                <DnsLogTable
                    ipLink={ipLink}
                    domainLink={false}
                    excludeQueryOptions={["companyName", "companyDomain"]}
                    initialParams={params} />
            )
        }
    },

    "tarpit-log": {
        title: "Tarpit Log",
        render: ({ id, ipLink }) => {
            const params = {
                customerCompanyId: id,
                withPayload: true,
                withHeaders: true,
                withCompany: false,
                withCidr: false,
                withThreat: true
            }
            return (
                <TarpitLogTable
                    ipLink={ipLink}
                    excludeQueryOptions={["companyName", "companyDomain"]}
                    initialParams={params} />
            )
        }
    },

    "affected-ports": {
        title: "Affected ports",
        render: ({ id, ipLink }) => {
            const params = {
                customerCompanyId: id,
                withCountry: true
            }
            return (
                <IpTarpitPort
                    initialParams={params}
                    ipLink={ipLink} />
            )
        }
    },

    threats: {
        render: ({ id }) => (
            <CompanyThreats companyId={id} titleLevel={2} />
        )
    },

    "top-victims": {
        render: ({ id, ipLink }) => {
            const params = {
                customerCompanyId: id,
            }
            return (
                <TopVictims
                    titleLevel={2}
                    params={params}
                    showCompanies={false}
                    ipLink={ipLink} />
            )
        }
    },

    "top-countries": {
        render: ({ id, onTopCountriesLoaded, countryCharts, countriesLoading }) => {
            const params = {
                companyId: id
            }
            return (
                <>
                    <TopCountries
                        titleLevel={2}
                        params={params}
                        countryLink={false}
                        onDataLoad={onTopCountriesLoaded} />
                    {countryCharts.map((chart, inx) => (
                        <section key={inx}>
                            <Spin spinning={countriesLoading}>
                                <Typography.Title level={2}>{chart.title}</Typography.Title>
                                <div className="chart-wrapper">
                                    <Chart {...chart.config} />
                                </div>
                            </Spin>
                        </section>
                    ))}
                </>
            )
        }
    },
    "company-ips": {
        render: ({ id }) => (
            <CompanyIps companyId={id} titleLevel={2} />
        )
    },

    watchlists: {
        title: "Watchlists",
        render: ({ id, ipLink, onWatchlistsLoaded }) => (
            <Watchlists
                companyId={id}
                showToolbar={false}
                onLoad={onWatchlistsLoaded}
                ipLink={ipLink} />
        )
    },

    "potential-phishing-domains": {
        title: "Potential Phishing Domains",
        render: ({ companyDomainSimilar, refetchCompany }) => (
            <PotentialPhishingDomains
                onStatusChange={refetchCompany}
                data={companyDomainSimilar} />
        )
    },

    "ip-count-by-date": {
        render: ({ id }) => {
            const params = {
                customerCompanyId: id,
                metric: "ip_count"
            }
            return (
                <ByDate
                    params={params}
                    titleLevel={2}
                    title="Ip count by date"
                    label="Ip count" />
            )
        }
    },

    "dns-by-date": {
        render: ({ id }) => {
            const params = {
                customerCompanyId: id,
            }
            return (
                <DnsByDate params={params} titleLevel={2} />
            )
        }
    },

    "tarpit-by-date": {
        render: ({ id }) => {
            const params = {
                customerCompanyId: id,
            }
            return (
                <TarpitByDate params={params} titleLevel={2} />
            )
        }
    }
};



async function loadCountiesByDate(options = {}) {

    const after = moment().subtract(31, "day").format("YYYY-MM-DD");
    const { country, companyId } = options;
    const { data, error } = await supabase.from("company_country_date")
        .select()
        .in("country", country)
        .eq("company_id", companyId)
        .gt("date", after);

    return { data, error };
}


async function loadThreatsCount({ companyId }) {
    const { count } = await supabase
        .from('company_report_threat')
        .select('*', { count: 'exact', head: true })
        .eq("company_id", companyId);
    return count || 0;
}

async function loadYesterdayStats({ companyId }) {
    const { data, error } = await supabase.from("company_report_date")
        .select()
        .eq("company_id", companyId)
        .eq("date", moment().subtract(1, "day").format("YYYY-MM-DD"));
    error && console.log(error)
    return { data, error };
}

async function loadGeneralStats({ companyId }) {
    let { data, error } = await supabase.from("company_stats").select().eq("company_id", companyId);
    const yesterday = await loadYesterdayStats({ companyId });
    const threatsCount = await loadThreatsCount({ companyId })
    if (!data || data.length === 0) {
        data = [{}]
    }
    data[0].yesterday = yesterday?.data?.[0] || 0;
    data[0].threat_count = threatsCount || 0;
    error && console.log(error)
    return { data, error };
}

function CompanyDashboard() {

    useHtmlClass("page-company");
    const { params: { id } } = useMatch("/company/:id");
    const [topCountries, setTopCountries] = useState(null);
    // const [hasWatchlists, setHasWatchlists] = useState(false);
    const [wlStats, setWlStats] = useState();
    const app = useContext(AppContext);

    const { data: company, isLoading: isCompanyLoading, refetch: refetchCompany } = useQuery(
        () => {
            const graph = `
                id, name, logo, created_at, 
                company_domain!company_domain_company_id_fkey(
                    domain
                ),
                company_domain_similar(
                    company_id, domain, similar_domain, takedown_requested, 
                    request_status, status_changed_at
                )`;
            return supabase.from("company").select(graph).eq("id", id)
        },
        [id],
        {
            initialData: {},
            prepare: data => data[0] || {}
        }
    );

    const logoSrc = useMemo(
        () => {
            if (company?.logo) {
                const cdn = app.get("cdnUrl");
                return `${cdn}/${company.logo.key}`;
            }
            return null;
        },
        [company, app]
    );

    const { data: stats, isLoading: statsLoading } = useQuery(
        loadGeneralStats,
        [id],
        {
            params: {
                companyId: id
            },
            initialData: {},
            prepare: data => data?.[0] || {}
        }
    );

    const { data: countriesByDate, isLoading: countriesLoading } = useQuery(
        loadCountiesByDate,
        [id, topCountries],
        {
            enabled: !!topCountries && topCountries.length > 0,
            params: {
                companyId: id,
                country: topCountries
            }
        }
    );

    const onTopCountriesLoaded = useCallback(
        (data) => {
            setTopCountries(data.map(row => row.country));
        },
        []
    );

    const onWatchlistsLoaded = useCallback(
        (data) => {
            // setHasWatchlists(data.length > 0);
            let ip_count = 0, matched_ip_count = 0;
            data.forEach(w => {
                ip_count += w.ip_count;
                matched_ip_count += w.matched_ip_count;
            });
            setWlStats({
                ip_count,
                matched_ip_count
            })
        },
        []
    );

    const companyIpStatsColor = useMemo(
        () => {
            if (!stats) {
                return { height: 0 }
            }
            const pos = stats.victim_ip_count / stats.ip_count;
            const height = (pos * 100) + "%";
            const color = colorBetween(colorStart, colorEnd, pos);
            return { height, backgroundColor: "#" + color };
        },
        [stats]
    );

    const companyWatchStatsColor = useMemo(
        () => {
            if (!wlStats) {
                return { height: 0 }
            }
            const pos = wlStats.matched_ip_count / wlStats.ip_count;
            const height = (pos * 100) + "%";
            const color = colorBetween(colorStart, colorEnd, pos);
            //console.log(colorStart, colorEnd, pos, color)
            return { height, backgroundColor: "#" + color };
        },
        [wlStats]
    );


    const dates = useMemo(
        () => {
            if (!countriesByDate || countriesByDate.length === 0) {
                return [];
            }
            return countriesByDate.map(row => row.date)
                .filter((d, inx, self) => self.indexOf(d) === inx)
                .sort()
        },
        [countriesByDate]
    );

    const charts = useMemo(
        () => {

            if (!countriesByDate || countriesByDate.length === 0) {
                return [];
            }

            const countries = countriesByDate.map(row => row.country)
                .filter((c, inx, self) => self.indexOf(c) === inx);

            return chartConfigs.map(cc => {

                const series = countries.map(c => {
                    const stats = countriesByDate.filter(row => row.country === c);

                    return getDefaultSeries(
                        dates.map(date => {
                            return stats.find(row => row.date === date) || {
                                country: c,
                                ip_count: 0,
                                dns_log_count: 0,
                                tarpit_log_count: 0
                            }
                        }),
                        {
                            y: {
                                field: cc.field,
                                name: countriesDict.find(ct => ct.code === c)?.name || "Unknown"
                            }
                        }
                    )
                });

                const options = getDefaultOptions(
                    dates.map(date => ({ date: (new Date(date)).getTime() }))
                );

                const chart = {
                    title: cc.title,
                    config: {
                        options,
                        series,
                        height: DEFAULT_HEIGHT,
                        type: "area"
                    }
                };
                return chart;
            })
        },
        [countriesByDate, dates]
    );

    const ipLink = useCallback(
        (ip) => (
            <IpLink ip={ip} companyId={id} />
        ),
        [id]
    );

    return (
        <Page className="page-company">
            <Portal host="header">
                <Spin spinning={isCompanyLoading}>
                    <Flex
                        vertical={false}
                        justify="space-between"
                        align="center"
                        gap="1rem"
                        className="page-company-name">

                        <div>
                            <Typography.Title level={1}>
                                {company.name}
                            </Typography.Title>

                            {company?.company_domain?.length > 0 &&
                                <Flex vertical={false}
                                    gap="0 1rem"
                                    wrap
                                    className="page-company-domains"
                                    align="flex-end">
                                    {company.company_domain.map(d => (
                                        <Link
                                            key={d.domain}
                                            to={`/reports/scan/${d.domain}`}>{d.domain}</Link>
                                    ))}
                                </Flex>}
                        </div>
                        {logoSrc &&
                            <div className="page-company-logo">
                                <img alt="" src={logoSrc} />
                            </div>}
                    </Flex>
                </Spin>

                <div className="page-general-stats">
                    <Spin spinning={statsLoading}>
                        <div className="dashboard-general-stats-item flippable">
                            {stats.victim_ip_count > 0 &&
                                <div className="dashboard-general-stats-color"
                                    style={{ height: "100%", backgroundColor: '#' + colorEnd, opacity: 0.5 }} />}
                            <div className="stats-card-front">
                                <h4>{stats ? formatNumber(stats.victim_ip_count) : <>&nbsp;</>}</h4>
                                <p>Victim IPs registered</p>
                            </div>
                            <div className="stats-card-back">
                                <h4>{stats ? formatNumber(stats.yesterday?.ip_count) : <>&nbsp;</>}</h4>
                                <p>Yesterday</p>
                            </div>
                        </div>
                    </Spin>

                    <Spin spinning={statsLoading}>
                        <div className="dashboard-general-stats-item flippable">
                            <div className="dashboard-general-stats-color" style={companyIpStatsColor} />
                            <div className="stats-card-front">
                                <h4>{stats ?
                                    stats.ip_count > 1000 ?
                                        ">" + formatNumber(1000) :
                                        formatNumber(stats.ip_count) :
                                    <>&nbsp;</>}</h4>
                                <p>Company IP(s)</p>
                            </div>
                            <div className="stats-card-back">
                                <h4>{stats ?
                                    formatNumber(((stats.victim_ip_count || 0) / (stats.ip_count || 1)) * 100) + "%" :
                                    <>&nbsp;</>}</h4>
                                <p>IPs compromised</p>
                            </div>
                        </div>
                    </Spin>

                    <Spin spinning={!wlStats}>
                        <div className="dashboard-general-stats-item flippable">
                            <div className="dashboard-general-stats-color" style={companyWatchStatsColor} />
                            <div className="stats-card-front">
                                <h4>{wlStats ?
                                    wlStats.ip_count > 1000 ?
                                        ">" + formatNumber(1000) :
                                        formatNumber(wlStats.ip_count) :
                                    <>&nbsp;</>}</h4>
                                <p>Watched IP(s)</p>
                            </div>
                            <div className="stats-card-back">
                                <h4>{wlStats ?
                                    formatNumber((wlStats.matched_ip_count / (wlStats.ip_count || 1)) * 100) + "%" :
                                    <>&nbsp;</>}</h4>
                                <p>IPs compromised</p>
                            </div>
                        </div>
                    </Spin>

                    <Spin spinning={statsLoading}>
                        <div className="dashboard-general-stats-item flippable">
                            <div className="stats-card-front">
                                <h4>{stats ? formatNumber(stats.threat_domain_count) : <>&nbsp;</>}</h4>
                                <p>Threat domains</p>
                            </div>
                            <div className="stats-card-back">
                                <h4>{stats ? formatNumber(stats.threat_count) : <>&nbsp;</>}</h4>
                                <p>Threats</p>
                            </div>
                        </div>
                    </Spin>

                    <Spin spinning={statsLoading}>
                        <div className="dashboard-general-stats-item flippable">
                            <div className="stats-card-front">
                                <h4>{stats ? formatNumber(stats.dns_log_count) : <>&nbsp;</>}</h4>
                                <p>Total DNS requests</p>
                            </div>
                            <div className="stats-card-back">
                                <h4>{stats ? formatNumber(stats.yesterday?.dns_log_counter) : <>&nbsp;</>}</h4>
                                <p>Yesterday</p>
                            </div>
                        </div>
                    </Spin>

                    <Spin spinning={statsLoading}>
                        <div className="dashboard-general-stats-item flippable">
                            <div className="stats-card-front">
                                <h4>{stats ? formatNumber(stats.tarpit_log_count) : <>&nbsp;</>}</h4>
                                <p>Total payload requests</p>
                            </div>
                            <div className="stats-card-back">
                                <h4>{stats ? formatNumber(stats.yesterday?.tarpit_log_counter) : <>&nbsp;</>}</h4>
                                <p>Yesterday</p>
                            </div>
                        </div>
                    </Spin>
                </div>
            </Portal>

            <div className="page-dashboard-reports-row single page-company-map">
                <Typography.Title level={3}>Last 30 days activity</Typography.Title>
                <LastActiveIpsMap companyId={id} />
            </div>

            <LayoutWithMenu
                menu={menu}
                defaultSelectedKeys={["watchlists"]}
                defaultOpenKeys={["watchlists"]}
                pages={pageConfigs}
                pageLoadOptions={{ id }}
                pageRenderOptions={{
                    id,
                    ipLink,
                    onTopCountriesLoaded,
                    countryCharts: charts,
                    countriesLoading,
                    companyDomainSimilar: company?.company_domain_similar,
                    refetchCompany,
                    onWatchlistsLoaded
                }}
                layoutProps={{ style: { gap: "1rem" } }}
                siderProps={{ width: "11rem" }}
                contentProps={{ style: { paddingTop: "0.1rem" } }} />
        </Page>
    )
}

export default CompanyDashboard