import { theme } from "antd"

export const breakpoints = {
    mobile: 0,
    tablet: 800,
    desktop: 1024,
    max: 1440
}

const config = {
    algorithm: theme.darkAlgorithm,
    token: {
        colorPrimary: "#3666d6",
        fontFamily: 'Titillium Web'
    },
    components: {
        Layout: {
            siderBg: "transparent",
            bodyBg: "transparent",
        },
        Menu: {
            darkItemBg: "transparent",
            darkItemSelectedBg: "#181b23",
            darkItemSelectedColor: "#fff",
            activeBarBorderWidth: 0,
            darkSubMenuItemBg: "transparent",
            darkSubMenuItemSelectedBg: "rgba(18, 18, 18, 1)",
        }
    }
}

export default config;