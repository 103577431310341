import { useRef } from "react"

function useDictRef(dict, autoUpdate = true) {

    const ref = useRef({ ...dict });
    if (autoUpdate) {
        Object.assign(ref.current, dict);
    }

    return ref.current;
}

export default useDictRef