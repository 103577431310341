import { useMemo, useState } from "react"
import { Typography, Spin, Table, Segmented } from "antd"
import { Link } from "react-router-dom"

import useQuery from "hooks/useQuery"

import supabase from "lib/supabase/main"

import { periodOptionsWithAll } from "lib/periodOptions"
import countries from "dict/countries.json"
import formatNumber from "lib/format/number"
import CountryName from "components/CountryName"

const { Title } = Typography;


const columns = [
    {
        dataIndex: "country",
        key: "country",
        title: "Country",
        render: country => {
            //const name = countries.find(c => c.code === country)?.name || "Unknown";
            const c = countries.find(c => c.code === country);
            return (
                <Link to={`/reports/country/${country}`} children={
                    <CountryName country={c} />
                } />
            )
        }
    },
    {
        dataIndex: "ip_count",
        key: "ip_count",
        title: "Ip count",
        render: formatNumber
    },
    {
        dataIndex: "dns_log_count",
        key: "dns_log_count",
        title: "Dns log entries",
        render: formatNumber
    },
    {
        dataIndex: "tarpit_log_count",
        key: "tarpit_log_count",
        title: "Tarpit log entries",
        render: formatNumber
    },
]

async function loadTopCountries(options = {}) {

    const { data: { rows, columns }, error } = await supabase.functions.invoke("query", {
        body: {
            report: "top_countries",
            options,
            //replica: true
        },
        method: "POST"
    });

    return { data: rows, columns, error }
}

function TopCountries({ title = "Top countries",
    params = {},
    countryLink = true,
    onDataLoad = null,
    titleLevel = 4,
    ...rest }) {

    const [period, setPeriod] = useState(params.period || "");
    const { data, isLoading } = useQuery(
        loadTopCountries,
        [period, ...(Object.values(params))],
        {
            onLoad: onDataLoad,
            params: {
                limit: 10,
                period,
                ...params
            }
        },
        Object.values(params)
    );

    const tableColumns = useMemo(
        () => {
            let tableColumns = [...columns];
            if (countryLink === false) {
                tableColumns[0].render = country => (
                    <CountryName country={countries.find(c => c.code === country)} />
                )
                // return countries.find(c => c.code === country)?.name || "Unknown";
            }
            if (period === "" || period === "all") {
                return tableColumns;
            }
            const inx = tableColumns.findIndex(c => c.dataIndex === "ip_count");
            tableColumns.splice(inx, 1);
            return tableColumns;
        },
        [period, countryLink]
    )

    return (
        <div className="small-report report-top-threat-domains">
            <div className="toolbar">
                {title && <Title level={titleLevel}>{title}</Title>}
                <Segmented
                    className="toolbar-right"
                    value={period}
                    onChange={setPeriod}
                    options={periodOptionsWithAll} />
            </div>
            <Spin spinning={isLoading}>
                <Table
                    className="table-transparent-header"
                    sticky
                    size="small"
                    loading={isLoading}
                    dataSource={data}
                    columns={tableColumns}
                    rowKey="country"
                    pagination={false}
                    {...rest} />
            </Spin>
        </div>
    )

}

export default TopCountries