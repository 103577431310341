import supabase from "lib/supabase/main";

export const DEFAULT_PAGE_SIZE = 100;

export async function loadDnsIntelLog(options = {}) {

    const {
        page = 0,
        pageSize = DEFAULT_PAGE_SIZE,
        query = null,
        queryBy = null,
        domain = null,
    } = options;

    const { data: { rows }, error } = await supabase.functions.invoke("query", {
        body: {
            report: "dnsintel_log",
            options: {
                query,
                queryBy,
                domain,
                offset: page * pageSize,
                limit: pageSize
            }
        },
        method: "POST"
    });

    return {
        data: rows,
        error,
        hasMore: rows.length >= 0
    };
}
