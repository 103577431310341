import { Select } from "antd"
import supabase from "lib/supabase/main";
import { useCallback, useEffect, useState } from "react"


function MalwareSelect(props) {

    const { onInitialLoad, onPressEnter, ...inputProps } = props;
    // delete inputProps.onPressEnter;

    const [initialOptionsLoaded, setInitialOptionsLoaded] = useState(false);
    const [options, setOptions] = useState([]);

    const onSearch = useCallback(
        async (query) => {
            //console.log("search", query)
            const req = supabase.from("threat").select();
            if (query) {
                req.ilike("name", `%${query}%`);
            }
            const { data } = await req;
            const options = data.map(row => ({
                value: row.id,
                label: row.name
            }));
            setOptions(options);

            if (!initialOptionsLoaded) {
                setInitialOptionsLoaded(true);
                onInitialLoad && onInitialLoad(options);
            }
        },
        [initialOptionsLoaded, onInitialLoad]
    );

    useEffect(
        () => void onSearch(""),
        // eslint-disable-next-line
        []
    );


    return (
        <Select
            showSearch
            defaultActiveFirstOption={false}
            filterOption={false}
            onSearch={onSearch}
            notFoundContent={null}
            options={options}
            {...inputProps} />
    )
}

export default MalwareSelect